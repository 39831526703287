/* eslint-disable */
import React from 'react';
import { TypoS, TypoSBold, TypoXXS, TypoXXSBold } from '@atoms/Typos';
import { Desktop, Mobile } from '@components/Layout';
import IconCoin from '@svg-icons/IconCoin';
import type { Property } from '@services/hafh/types/generated';
import Image from 'next/image';
import styles from './css';

interface Props {
  onClick: (property: Property) => void;
  property: Property;
}

const PropertyItem = ({ onClick, property }: Props) => (
  <div className="featured-property-card" onClick={() => onClick(property)}>
    <div className="featured-property-card-image">
      <Image
        alt={property.name}
        fill={true}
        style={{
          objectFit: 'cover',
        }}
        src={property.thumbnail_url}
      />
    </div>
    <div className="content-container">
      <div className="featured-property-card-name">
        <Desktop>
          <TypoSBold text={property.name} />
        </Desktop>
        <Mobile>
          <TypoXXSBold text={property.name} />
        </Mobile>
      </div>
      <div className="description-wrapper">
        <div className="location">
          <Desktop>
            <TypoS color="black600" text={property.prefecture} />
          </Desktop>
          <Mobile>
            <TypoXXS color="black600" text={property.prefecture} />
          </Mobile>
        </div>
        <div className="coin-info">
          <Desktop>
            <IconCoin size="md" />
          </Desktop>
          <Mobile>
            <IconCoin size="sm" />
          </Mobile>
          <div className="number">
            <Desktop>
              <TypoS color="black600" text={`${property.coin_range?.[0]}~`} />
            </Desktop>
            <Mobile>
              <TypoXXS color="black600" text={`${property.coin_range?.[0]}~`} />
            </Mobile>
          </div>
        </div>
      </div>
    </div>
    <style jsx={true}>{styles}</style>
  </div>
);

export default PropertyItem;
