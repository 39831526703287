/* eslint-disable */
import React from 'react';

import PropertyItem from '@organisms/PropertyItem';

import type { Property } from '@services/hafh/types/generated';

import styles from './css';

interface Props {
  properties: Property[];
  onClickProperty: (property: Property) => void;
}

const PropertyItems = ({ properties, onClickProperty }: Props) => {
  return (
    <div className="featured-property-cards">
      {properties.map((property) => (
        <PropertyItem
          onClick={onClickProperty}
          property={property}
          key={property.id}
        />
      ))}
      <style jsx>{styles}</style>
    </div>
  );
};

export default PropertyItems;
