import React from 'react';
import Button from '@atoms/Button';
import {
  TypoLBold,
  TypoMBold,
  TypoXSBold,
  TypoXXXS,
  TypoXXXSBold,
} from '@atoms/Typos';
import { Desktop, Mobile } from '@components/Layout';
import { useIsSignupDiscountCampaignActive } from '@hooks/useCampaign';
import { useIsSignUpAvailable } from '@hooks/useIsSignUpAvailable';
import NeighborPlans from '@molecules/NeighborPlans';
import routes from '@utils/routes';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import styles from './css';

type Props = {
  showReferralCodeBonus?: boolean;
};

const ConvertSection = ({
  showReferralCodeBonus = false,
}: Props): JSX.Element => {
  const { t } = useTranslation('lp');

  const isRegisterDiscountCampaignActive = useIsSignupDiscountCampaignActive();
  const isSignUpAvailable = useIsSignUpAvailable();

  if (isRegisterDiscountCampaignActive) {
    return (
      <div className="signup-campaign-cta-cont">
        <div className="signup-campaign-cta-cont-title-top">
          <TypoLBold color="purple700" text="完全招待制" />
          <TypoXXXSBold color="purple700" text="※1" />
        </div>
        <TypoLBold color="purple700" text="新規登録キャンペーン" />
        <div className="signup-campaign-period">
          <TypoXSBold color="black600" text="期間：" />
          <TypoXSBold color="black900" text="2024︎年 9︎月 12︎日 まで" />
        </div>
        <div className="signup-campaign-cta-inner">
          <Mobile>
            <Image
              alt="完全招待制新規登録キャンペーン詳細"
              height={271}
              quality={100}
              src="/images/campaign/restart202408/cta-discount-mobile.png"
              style={{
                height: 'auto',
                width: '100%',
              }}
              width={448}
            />
            <Image
              alt="完全招待制新規登録キャンペーン詳細"
              height={271}
              quality={100}
              src="/images/campaign/restart202408/cta-bonus-mobile.png"
              style={{
                height: 'auto',
                width: '100%',
              }}
              width={448}
            />
          </Mobile>
          <Desktop>
            <Image
              alt="完全招待制新規登録キャンペーン詳細"
              height={207}
              quality={100}
              src="/images/campaign/restart202408/cta-discount-desktop.png?v=1"
              style={{
                height: 'auto',
                maxWidth: 452,
                width: '100%',
              }}
              width={448}
            />
            <Image
              alt="完全招待制新規登録キャンペーン詳細"
              height={207}
              quality={100}
              src="/images/campaign/restart202408/cta-bonus-desktop.png"
              style={{
                height: 'auto',
                maxWidth: 452,
                width: '100%',
              }}
              width={448}
            />
          </Desktop>
        </div>
        <div className="signup-campaign-notice">
          <TypoXXXS
            text={
              '※1 ご登録には既存HafH会員様からの招待コードが必要です。各招待コードには招待上限数があります。\n※2 招待特典の受け取りには、本人確認完了が必要です'
            }
          />
        </div>
        <div className="signup-campaign-button">
          <Button
            label="登録する"
            link={routes.signup}
            size="large"
            width="responsive"
          />
        </div>
        <style jsx={true}>{styles}</style>
      </div>
    );
  }

  return (
    <div className="convert-section">
      <div className="convert-section-body">
        <h2 className="convert-heading">
          <Mobile>
            <TypoMBold text={t('choosePlan')} />
          </Mobile>
          <Desktop>
            <TypoLBold text={t('choosePlan')} />
          </Desktop>
        </h2>
        <div className="neighbor-plans-wrapper">
          <NeighborPlans
            showAboutCoin={false}
            showNotice={false}
            showReferralCodeBonus={showReferralCodeBonus}
            showTestimonial={false}
          />
        </div>
        <div className="call-to-action-button">
          {isSignUpAvailable ? (
            <Button
              label={
                showReferralCodeBonus
                  ? t('convertSectionButton')
                  : t('registerNow')
              }
              link={routes.signup}
              size="large"
              width="responsive"
            />
          ) : (
            <Button
              disabled={true}
              label={t('registerNow')}
              size="large"
              width="responsive"
            />
          )}
        </div>
      </div>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default ConvertSection;
