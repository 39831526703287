import React from 'react';
import Button from '@atoms/Button';
import { TypoMBold, TypoSBold, TypoXSBold } from '@atoms/Typos';
import { Desktop, Mobile } from '@components/Layout';
import Link from '@components/Link';
import { useAppSelector } from '@hooks/useAppSelector';
import {
  useCampaignImagePath,
  useIsSignupDiscountCampaignActive,
} from '@hooks/useCampaign';
import { useIsSignUpAvailable } from '@hooks/useIsSignUpAvailable';
import useLocation from '@hooks/useLocation';
import { getNeighborOnFreePlan } from '@utils/neighbor';
import routes from '@utils/routes';
import { pushDataLayer } from '@utils/spa-ga4';
import { isEmpty } from '@utils/utils';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import styles from './css';

type Props = { isAdlp?: boolean; isInvitedPage?: boolean };

const RegisterStickyBanner = ({ isAdlp, isInvitedPage }: Props) => {
  const { t } = useTranslation('common');
  const { loadingNeighbor, neighbor } = useAppSelector((state) => ({
    loadingNeighbor: state.loadingNeighbor,
    neighbor: state.neighbor,
  }));
  const { isJapan } = useLocation();

  const isRegisterDiscountCampaignActive = useIsSignupDiscountCampaignActive();
  const isFreePlan = getNeighborOnFreePlan(neighbor);

  const campaignImagePath = useCampaignImagePath();

  const isSignUpAvailable = useIsSignUpAvailable();

  if (
    !isSignUpAvailable ||
    (!(isFreePlan && isRegisterDiscountCampaignActive) &&
      (!isEmpty(neighbor) || loadingNeighbor))
  ) {
    return null;
  }

  return (
    <Link
      href={
        isRegisterDiscountCampaignActive
          ? isFreePlan
            ? routes.subscribe
            : routes.signup
          : isAdlp
          ? routes.japanLP
          : routes.signup
      }
      onClick={() =>
        pushDataLayer({
          campaign_code: '',
          event: 'gtm-click',
          event_name: 'banner_sticky',
        })
      }
    >
      {isRegisterDiscountCampaignActive ? (
        <div className="register-campaign-sticky-banner" />
      ) : (
        <div className="register-sticky-banner">
          <div className="sticky-banner-cont">
            {isJapan ? (
              isInvitedPage ? (
                <>
                  <TypoXSBold
                    color="white"
                    text={'いま登録すると\n招待特典がもらえる！'}
                  />
                  <Button
                    btnStyle="white"
                    label={<TypoXSBold color="purple600" text="登録へすすむ" />}
                    size="small"
                  />
                </>
              ) : (
                <>
                  <Desktop>
                    <Image
                      alt="sticky banner"
                      fill={true}
                      src="/images/banner/sticky-banner-pc.png?v=1"
                      style={{
                        margin: 'auto',
                        maxWidth: 1280,
                        objectFit: 'cover',
                      }}
                      unoptimized={true}
                    />
                  </Desktop>
                  <Mobile>
                    <Image
                      alt="sticky banner"
                      height={60}
                      src="/images/banner/sticky-banner-mobile.png?v=2"
                      unoptimized={true}
                      width={375}
                    />
                  </Mobile>
                </>
              )
            ) : (
              <>
                <Mobile>
                  <TypoSBold color="white" text={t('signUpNow')} />
                </Mobile>
                <Desktop>
                  <TypoMBold color="white" text={t('signUpNow')} />
                </Desktop>
              </>
            )}
          </div>
        </div>
      )}
      <style jsx={true}>{styles}</style>
    </Link>
  );
};

export default RegisterStickyBanner;
