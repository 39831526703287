/* eslint-disable */
import css from 'styled-jsx/css';

import { BREAKPOINT } from '@utils/constants';

const styles = css`
  .featured-property-cards {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @media screen and (min-width: ${BREAKPOINT.mobile}px) {
      margin-top: 40px;
    }

    > :global(.featured-property-card) {
      @media screen and (min-width: ${BREAKPOINT.mobile}px) {
        width: calc((100% - 48px) / 4);
        margin: 0 16px 24px 0;
        &:nth-child(4n) {
          margin-right: 0;
        }

        &:nth-child(n + 5) {
          margin-bottom: 0;
        }
      }
      @media screen and (max-width: ${BREAKPOINT.mobile}px) {
        width: calc((100% - 8px) / 2);
        margin: 0 8px 16px 0;
        &:nth-child(2n) {
          margin-right: 0;
        }

        &:nth-child(n + 7) {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export default styles;
