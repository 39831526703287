/* eslint-disable */
import { TypoXS, TypoXSBold } from '@atoms/Typos';

const userTestimonials = [
  {
    img: 'matsu_hotel-life.png',
    profile: 'matsu_hotel.life（20代 男性）',
    duration: '2〜3年',
    title: 'ホテルへの安心感が桁違い！',
    text: (
      <TypoXS>
        この2年間で、約50泊の宿泊でHafHを活用✨
        <br />
        <TypoXSBold color="red500">掲載されているホテルへの安心感</TypoXSBold>
        が
        <br />
        <TypoXSBold color="red500">他の予約サービスとは桁違い</TypoXSBold>
        なんです！ <br />
        もう旅するにはなくてはならない存在です！
      </TypoXS>
    ),
  },
  {
    img: 'kogumama1985.png',
    profile: 'kogumama1985（30代 女性）',
    duration: '1〜2年',
    title: 'HafHで息子と嬉しい東京二人旅！',
    text: (
      <TypoXS>
        今年の自分の誕生日プレゼントを、
        <br />
        <TypoXSBold color="red500">息子との東京二人旅</TypoXSBold>
        にしてホテルを
        <br />
        HafHさんで予約しました‼️
        <br />
        <TypoXSBold color="red500">二人で嬉しいwin-winな旅行</TypoXSBold>
        になりました😍
      </TypoXS>
    ),
  },
  {
    img: 'yamana9z.png',
    profile: 'yamana9z（30代 男性）',
    duration: '3〜6ヶ月 ',
    title: '出張と旅行もHafHでお得に宿泊！',
    text: (
      <TypoXS>
        HafHを使って出張と旅行を楽しんでいます😊
        <br />
        HafHで
        <TypoXSBold color="red500">お手軽な価格で泊まれるのが嬉しい</TypoXSBold>
        です！ 今後は海外旅行でも使っていきたいと思っています(^^)！！！
      </TypoXS>
    ),
  },
  {
    img: 'honamin1011.png',
    profile: '@honamin1011（30代 女性）',
    duration: '1〜2年',
    title: 'え！この立地でこのお値段！？',
    text: (
      <TypoXS>
        遠征組ってとりあえずホテル押さえるよね？
        <br />
        最近はどこも高騰しててびっくりするけど
        <TypoXSBold color="red500">
          HafHだと「この立地でこのお値段いいんすか！？」がいっぱい
        </TypoXSBold>
        あるからよく使ってる！
      </TypoXS>
    ),
  },
  {
    img: 'vakabondaimi.png',
    profile: '@vakabondaimi（20代 女性）',
    duration: '2〜3年',
    title: 'パパッと予約でらくらくステイ！',
    text: (
      <TypoXS>
        3週間の北海道ドライブ旅も、
        <TypoXSBold color="red500">HafHでらくらくステイ</TypoXSBold>。
        <br />
        <TypoXSBold color="red500">前日でもパパッと予約ができる</TypoXSBold>
        ので、気分次第で予定が変わりがちな私にはぴったり。
      </TypoXS>
    ),
  },
  {
    img: 'mikaatsu5568.png',
    profile: 'mikaatsu5568（40代 女性）',
    duration: '6〜12ヶ月',
    title: '子連れ旅には本当にありがたい！',
    text: (
      <TypoXS>
        HafHに出会ってどこへ行こうか？
        <br />
        <TypoXSBold color="red500">旅のワクワクが止まりません！</TypoXSBold>
        <br />
        <TypoXSBold color="red500">ギリギリまでキャンセルできるHafH</TypoXSBold>
        は子連れ旅には本当にありがたいです！
      </TypoXS>
    ),
  },
  {
    img: 'payaya_tabi.png',
    profile: '@payaya_tabi（20代 男性）',
    duration: '2〜3年',
    title: '新しいホテルとの出会いが楽しい！',
    text: (
      <TypoXS>
        累計で24泊もしてました😳 ずっと継続しています。
        高級ホテル目的というより、
        <br />
        <TypoXSBold color="red500">
          繁忙期の宿確保で大変助けられています😌 
        </TypoXSBold>
        <br />
        <TypoXSBold color="red500">新しいホテルとの出会い</TypoXSBold>
        も楽しいです👍
      </TypoXS>
    ),
  },
  {
    img: 'manachikuwa.png',
    profile: 'manachikuwa（50代 女性）',
    duration: '3〜6ヶ月 ',
    title: 'HafHという心強い相棒！',
    text: (
      <TypoXS>
        セカンドハウスのようにぶらりと訪れ、
        <TypoXSBold color="red500">
          自分のためだけにゆったり時間を使う。
        </TypoXSBold>
        コレ至福です💕
        <TypoXSBold color="red500">HafHという心強い相棒</TypoXSBold>
        と一緒に、これからも自分時間を楽しみたいと思います😊
      </TypoXS>
    ),
  },
];

export default userTestimonials;
