import COLORS from '@utils/colors';
import css from 'styled-jsx/css';

const styles = css`
  .top-faq-wrapper {
    margin: 0 auto 96px;
    padding: 16px;
    .top-faq {
      display: flex;
      flex-direction: column;
      align-items: center;
      .top-faq-header {
        margin-bottom: 24px;
      }

      .top-faq-collapse-wrapper {
        width: 100%;
        max-width: 673px;
      }

      .top-faq-link {
        margin-top: 32px;
      }
    }
    .divider {
      background-color: ${COLORS.primary};
      height: 3px;
      margin: 16px auto 0;
    }
  }
`;

export default styles;
