import React from 'react';
import Swiper from '@atoms/Swiper';
import { TypoMBold, TypoXLBold, TypoXS, TypoXSBold } from '@atoms/Typos';
import userTestimonials from '@utils/user-testimonials';
import classNames from 'classnames';
import Image from 'next/image';
import { SwiperSlide } from 'swiper/react';
import styles from './css';

const options = {
  slidesPerView: 'auto',
  spaceBetween: 20,
} as const;

type Props = {
  backgroundColor?: string;
  customTitle?: JSX.Element;
};

const TestimonialSlider = ({ backgroundColor, customTitle }: Props) => (
  <div
    className={classNames(
      'testimonial-slider',
      backgroundColor && `bg-${backgroundColor}`
    )}
  >
    <div className="slider-title">
      {customTitle || <TypoXLBold text="利用者様の声" />}
      <div className="divider" />
    </div>
    <div className="testimonial-slider-container">
      {userTestimonials && (
        <Swiper
          key="user-testimonials"
          name="user-testimonials"
          options={options}
        >
          {userTestimonials.map((ut, index) => (
            <SwiperSlide key={ut.img}>
              <div className="testimonial-slider-item">
                <div className="testimonial-header-container">
                  <Image
                    alt={`ご利用者の声-${index}`}
                    height={48}
                    src={`/images/testimonial-users/${ut.img}`}
                    style={{ borderRadius: '50%' }}
                    width={48}
                  />
                  <div className="header-right">
                    <p className="neighbor-neighbor">
                      <TypoXSBold text={ut.profile} />
                    </p>
                    <p className="neighbor-occupation">
                      <TypoXS
                        color="black700"
                        text={`HafH利用歴：${ut.duration}`}
                      />
                    </p>
                  </div>
                </div>
                <h3 className="testimonial-title">
                  <TypoMBold text={ut.title} />
                </h3>
                <p className="testimonial-content">{ut.text}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
    <style jsx={true}>{styles}</style>
  </div>
);

export default TestimonialSlider;
