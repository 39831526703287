import COLORS from '@utils/colors';
import css from 'styled-jsx/css';

const BANNER_HEIGHT = 72;

const styles = css`
  .register-sticky-banner {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    cursor: pointer;
    z-index: 100;
    height: ${BANNER_HEIGHT}px;
    background: ${COLORS.primary};
    justify-content: center;
    align-items: center;
    display: flex;

    .sticky-banner-cont {
      display: flex;
      justify-content: center;
      gap: 8px;
      :global(span) {
        width: 195px;
      }
    }
  }

  :global(.page) {
    padding-bottom: ${BANNER_HEIGHT}px;
  }
`;

export default styles;
