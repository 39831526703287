import React from 'react';
import Button from '@atoms/Button';
import SectionHeader from '@atoms/SectionHeader';
import { TypoMBold, TypoS } from '@atoms/Typos';
import { Device } from '@components/Layout';
import { useAppSelector } from '@hooks/useAppSelector';
import { LANGUAGE_CODES } from '@utils/constants';
import { useIsGlbMarketTwLanguage } from '@utils/hooks';
import routes from '@utils/routes';
import Image from 'next/image';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import styles from './css';

const CARDS = [
  {
    id: 1,
    link: routes.about,
  },
  {
    id: 2,
    link: routes.coin,
  },
  {
    id: 3,
    link: routes.hafhPlan,
  },
];

const LPAboutHafHSection = () => {
  const router = useRouter();
  const { lang, t } = useTranslation('top');
  const { location } = useAppSelector((state) => ({
    location: state.location,
  }));
  const isGlbMarketTwLang = useIsGlbMarketTwLanguage(lang);

  return (
    <section className="about-hafh-section">
      <div className="about">
        <SectionHeader
          description={t('aboutHafHSection.desc')}
          heading={t('aboutHafHSection.heading')}
        />
      </div>
      <div className="about-cards">
        {CARDS.map((c) => (
          <div key={t(`aboutHafHSection.about${c.id}.title`)} className="card">
            <div
              className="image-container"
              onClick={() => router.push(c.link)}
            >
              <Image
                alt={t(`aboutHafHSection.about${c.id}.title`)}
                height={100}
                layout="responsive"
                src={`${process.env.NEXT_PUBLIC_GCS_URL}/top/about/${
                  isGlbMarketTwLang
                    ? LANGUAGE_CODES.taiwanese
                    : location.country?.code
                }-about-${c.id}.png`}
                width={100}
              />
            </div>
            <Device desktop={true} tablet={true}>
              <div className="card-contents">
                <h2 className="title">
                  <TypoMBold text={t(`aboutHafHSection.about${c.id}.title`)} />
                </h2>
                <div className="description">
                  <TypoS text={t(`aboutHafHSection.about${c.id}.desc`)} />
                </div>
                <div className="button-wrapper">
                  <Button
                    isSecondary={true}
                    label={t('aboutHafHSection.buttonLabel')}
                    onClick={() => router.push(c.link)}
                    size="large"
                  />
                </div>
              </div>
            </Device>
          </div>
        ))}
      </div>
      {}
      <style jsx={true}>{styles}</style>
    </section>
  );
};

export default LPAboutHafHSection;
