import React from 'react';
import Button from '@atoms/Button';
import Collapse from '@atoms/Collapse';
import { TypoXLBold } from '@atoms/Typos';
import Link from '@components/Link';
import COLORS from '@utils/colors';
import routes from '@utils/routes';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import styles from './css';

const JpTopFaq = () => {
  const { t } = useTranslation('jp-top');

  return (
    <div className="top-faq-wrapper">
      <div className="top-faq">
        <div className="top-faq-header">
          <h2>
            <TypoXLBold text={t('theFAQ')} />
            <div className="divider" />
          </h2>
        </div>
        <div className="top-faq-collapse-wrapper">
          {[...Array(4)].map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Collapse key={index} title={t(`faq${index + 1}Question`)}>
              <p className="faq-content">
                <Trans
                  components={{
                    br: <br />,
                    link: (
                      <Link
                        href={routes.properties}
                        style={{
                          color: COLORS.black,
                          textDecoration: 'underline',
                        }}
                        target="_blank"
                      />
                    ),
                    zenLink: (
                      <Link
                        href="https://hafh.zendesk.com/hc/ja/articles/4408784893849"
                        style={{
                          color: COLORS.black,
                          textDecoration: 'underline',
                        }}
                        target="_blank"
                      />
                    ),
                  }}
                  i18nKey={`jp-top:faq${index + 1}Answer`}
                />
              </p>
            </Collapse>
          ))}
        </div>
        <div className="top-faq-link">
          <Button
            isSecondary={true}
            label={t('faqMore')}
            onClick={() => window.open(routes.faq, '_blank')}
            size="large"
          />
        </div>
      </div>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default JpTopFaq;
