import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';
import css from 'styled-jsx/css';

const styles = css`
  .testimonial-slider {
    padding: 20px 0;
    margin: 32px 0;
    background: #fbfcfe;
    position: relative;
    &.bg-lightGray {
      background: ${COLORS.lightGray};
    }
    .slider-title {
      margin: 1rem auto;
      text-align: center;
      width: max-content;
      .divider {
        background-color: ${COLORS.primary};
        height: 3px;
        margin: 16px auto 24px;
      }
    }
    .testimonial-slider-container {
      padding: 0 20px;
      display: flex;
      :global(.swiper) {
        :global(.swiper-button-prev),
        :global(.swiper-button-next) {
          position: absolute;
          top: 36%;
          width: 40px;
          height: 40px;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          opacity: 0.9;
          &:hover {
            opacity: 1;
          }
        }
        :global(.swiper-button-disabled) {
          display: none;
        }
        :global(.swiper-button-next) {
          right: 24px;
        }
        :global(.swiper-slide) {
          width: 352px;
          @media (min-width: ${BREAKPOINT.desktop}px) {
            width: 432px;
          }
        }
        :global(.swiper-button-prev) {
          left: 24px;
          @media (max-width: ${BREAKPOINT.desktop}px) {
            display: none;
          }
        }
      }
      .testimonial-slider-item {
        width: 352px;
        height: 100%;
        margin-bottom: 4px;
        text-align: center;
        padding: 20px 16px;
        background: ${COLORS.white};
        border-radius: 12px;
        box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
        @media (min-width: ${BREAKPOINT.desktop}px) {
          height: 100%;
          width: 432px;
        }
        .testimonial-header-container {
          display: flex;
          justify-content: space-between;
          .header-right {
            width: 100%;
            text-align: left;
            margin-left: 16px;
          }
        }
        .neighbor-name {
          margin-top: 16px;
        }
        .neighbor-occupation {
          margin: 8px 0;
        }
        .testimonial-title {
          margin: 12px 0;
          @media (min-width: ${BREAKPOINT.desktop}px) {
            text-align: left;
          }
        }
        .testimonial-content {
          width: 100%;
          line-height: 1.5rem;
          text-align: left;
        }
      }
    }
  }
`;

export default styles;
