/* eslint-disable */
import css from 'styled-jsx/css';

import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';

const styles = css`
  .featured-property-card {
    cursor: pointer;

    > .featured-property-card-image {
      position: relative;
      background: ${COLORS.black300};
      width: 100%;
      aspect-ratio: 8 / 5;
      @media screen and (min-width: ${BREAKPOINT.mobile}px) {
        aspect-ratio: 6 / 5;
      }
    }

    > .content-container {
      margin-top: 4px;
      @media screen and (min-width: ${BREAKPOINT.mobile}px) {
        margin-top: 8px;
      }

      > .featured-property-card-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        > :global(.typo) {
          white-space: nowrap;
        }
      }

      > .description-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        > .coin-info {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-left: 4px;
          @media screen and (min-width: ${BREAKPOINT.mobile}px) {
            margin-left: 8px;
          }

          > .number {
            margin-left: 2px;
            @media screen and (min-width: ${BREAKPOINT.mobile}px) {
              margin-left: 4px;
            }
          }
        }
      }
    }
  }
`;

export default styles;
