import React from 'react';
import { TypoSBold } from '@atoms/Typos';
import AutoSuggestInHeader from '@molecules/AutoSuggestInHeader';
import { pushDataLayer } from '@utils/spa-ga4';
import styles from './css';

const onClickSearchWindow = () => {
  pushDataLayer({
    campaign_code: '',
    event: 'gtm-click',
    event_name: 'window_search',
  });
};

const TopSearchWindow = () => (
  <div className="top-search-window" onClick={onClickSearchWindow}>
    <div className="title">
      <TypoSBold color="white" text="HafHで泊まれる宿を検索" />
    </div>
    <AutoSuggestInHeader />
    <style jsx={true}>{styles}</style>
  </div>
);

export default TopSearchWindow;
