/* eslint-disable */
import css from 'styled-jsx/css';

import { BREAKPOINT } from '@utils/constants';

const styles = css`
  .about-hafh-section {
    max-width: 1248px;
    margin: 40px auto;
    padding: 0 120px;
    @media screen and (max-width: ${BREAKPOINT.mobile}px) {
      margin: 64px 0;
      padding: 0 16px;
    }
  }

  .about-cards {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: ${BREAKPOINT.mobile}px) {
      margin-top: 24px;
      flex-direction: column;
      gap: 16px;
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border: 1px solid #efeff0;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    cursor: pointer;

    & + & {
      margin-left: 24px;
    }

    @media screen and (max-width: ${BREAKPOINT.mobile}px) {
      & + & {
        margin: 0;
      }
    }

    .card-contents {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 32px 16px;
      text-align: center;

      > .description {
        margin-top: 16px;
      }

      > .button-wrapper {
        margin-top: 32px;
      }
    }
  }
`;

export default styles;
